import cloneDeep from 'lodash.clonedeep';
import moment from 'moment'
import isNil from "lodash.isnil";
import {LangName} from '~/config/cookieKeys'
import {
  default_country,
  default_currency,
  default_good_lang,
  default_site_lang,
  GET_SITE_ID,
  isBuildWebSite,
  PAY_URL
} from '~/config'
import { NativePages } from "~/enums/pageEnum";
import {NavTypeEnum} from "~/enums/siteEnum";
import {scrollTo} from "~/utils/dom";
import {getDeviceOs, isMobile} from "~/utils/types";
const getType = obj => Object.prototype.toString.call(obj);

function transMoney(value, dir = 2){
  let num = ''
  if (value === 0) {
    return parseFloat(value).toFixed(dir);
  }
  if (value !== '') {
    value += ''
    value = parseFloat(value.replace(/,/g, '')).toFixed(dir);
    if (!value.includes(".")) {
      num = value.replace(/\d{1,3}(?=(\d{3})+$)/g, function(s) {
        return s + ',';
      });
    } else {
      num = value.replace(/(\d)(?=(\d{3})+\.)/g, function(s) {
        return s + ',';
      });
    }
  } else {
    num = ""
  }
  return num
}


const isNumber = t => Object.prototype.toString.call(t).includes('Number')

const createId = (list = [], key='') => {
  if (!list.length || key === '') return '';
  function compare (key) {
    return function (a,b) {
      const v1 = a[key];
      const v2 = b[key];
      return v1 - v2;
    }
  }
  const result = list.sort(compare(key))
  return result[result.length - 1][key]+1;
}


const getQueryString = (name, url) => {
  const reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
  const r = url || window.location.search.substr(1).match(reg);
  if(r!=null)return  unescape(r[2]); return null;
}

//  获取商城语言环境
const getLang = (ctx) => {
  const that = ctx || ctx.app;
  const lang = that.$cookies.get(LangName) || 'en'
  if ( lang === 'zh') {
    return 'cn'
  } else {
    return lang
  }
}

export const formatSpePrice = (price) => {
  return Number(price).toString().includes('.') ? Number(price).toFixed(2) : Number(price).toString()
}

//  从path中获取商城语言环境
const getLangFromPath = (path) => {
  let lang = 'en';
  const pathSplit = path.split('/')
  let langIndex = 0;
  if (path.includes('preview')) { // 预览环境
    langIndex = 3
  } else { // 线上环境
    langIndex = 1
  }

  lang = pathSplit[langIndex] || 'en';

  return lang === 'zh' ? 'cn' : lang;
}

//   通过ip获取货币环境
const getLangByip = (ctx) => {
  const that = ctx || ctx.app;
  const lang = that.$store.state.user.ipInfo
  return lang
}

const transformLangToServer = (langStr) => {
  if (langStr === 'zh') {
    return 'cn'
  }
  return langStr
}

// 获取商店语言环境
// 设计时：固定为en
// 运行时：优先取路由参数，路由参数没有建站器的default_language; 如果改商品多语言设置了该语言环境，则显示该语言环境语言，否则显示en环境语言
const getStoreLang = (that) => {
  if (that.editing) {
    return default_site_lang;
  }
  const projectInfo = that.$store.state.project?.info?.project;
  // eslint-disable-next-line
  const { default_language, languages } = projectInfo;
  const lang = getNeedParamsFormRouteParams(that.$route.params)?.lang || default_language;

  // 网站语言包括在商品语言中，商品跟随网站路由中的lang语言；反之商品显示商品默认语言
  if (languages && languages.includes(lang)) {
    return lang
  } else {
    // eslint-disable-next-line
    return default_site_lang;
  }
}

// 动态加载js fix: 解决head()方法中异步加载完成无通知问题
const loadJsAsync = (src, async, options) => {
  return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = async;
      if (options) {
          for (const key in options) {
              script.setAttribute(key, options[key]);
          }
      }

      const onload = () => {
          /// console.info("js loaded: ", src);
          script.removeEventListener("load", onload);
          resolve();
      };

      script.addEventListener("load", onload);
      script.addEventListener("error", (err) => {
          script.removeEventListener("load", onload);
        // eslint-disable-next-line no-console
          console.error("loading js error: ", src, err);
          reject(new Error(`Failed to load ${src}`));
      });

      (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script);
  });
}
const formatDate = (timestamp, format) => {
  // todo 根据传日格式返回
  // timestamp 精确度为毫秒
  const date = new Date(timestamp);
  const obj = {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hour: date.getHours(),
    Minute: date.getMinutes(),
    seconds: date.getSeconds()
  }
  if (!format) {
    return `${obj.year}-${obj.month}-${obj.day} ${obj.hour <= 9 ? '0'+obj.hour : obj.hour}:${obj.Minute <= 9 ? '0'+obj.Minute : obj.Minute}:${obj.seconds<=9 ? '0'+obj.seconds : obj.seconds}`
  }
}

// 获取localStorage X-Forwarded-For值
const getxfip = (localStorage) => {
  let forwarded = {};
  // release 不加 X-Forwarded-For (暂时注释测试)
  // if (isRelease) {
  //   return forwarded;
  // }
  if (!localStorage) {
    // eslint-disable-next-line
    console.error('请传入localStorage')
    return forwarded;
  }
  const ip = localStorage.getItem('X-Forwarded-For') || '';
  if (ip) {
    forwarded = { 'X-Forwarded-For': ip }
  }
  return forwarded;
}


// 不能含小数点的货币，country_currency[]，使用向上取整
const ZERO_DECIMAL_CURRENCIES = [
  'BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'IDR',
  'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF',
  'HKD', 'TWD'
]

/**
 * 全建站器统一获取价格方法
 * 优先取ip对应货币价格，没有取usd, 再没有显示没有价格，暂时不支持显示0元
 *
 * @param {*} pricesSetting
 * @param {*} ipInfo
 * @param {*} that
 * @param {*} type - defaultPrice | originalPrice
 * @returns
 */

const getPriceInfo = (pricesSetting = {}, ipInfo = {
  currency: 'USD',country: 'US'
}, that = null, type = 'defaultPrice') => {
  if(!pricesSetting) return { currency: '', currencyLabel: '', price: '', riceTxt: '' }
  const valKey = type === 'defaultPrice'
    ? 'default_value'
    : 'original_value'
  const {currency, country}  = ipInfo
  const currencyMap = that.$store.state.project.currencieMap
  let info = {
    currency: 'USD',
    currencyLabel: '$',
    price: null,
    priceTxt: that.$i18n.t('siteBuild.store.noPrice')
  }
  if (!Object.keys(pricesSetting).length) {
    // eslint-disable-next-line no-console
    console.error('请传入价格对象：prices')
    return info;
  }
  if (!currency) {
    // eslint-disable-next-line no-console
    console.error('请传入默认货币：currency')
    return info;
  }
  if (!that) {
    // eslint-disable-next-line no-console
    console.error('请传入this', country)
    return info;
  }
  let price = 0
  // console.log(pricesSetting)
  if (pricesSetting[country] && pricesSetting[country][currency]) {
    price = pricesSetting[country][currency][valKey].toFixed(2)
  }
  const currencyLabel = currencyMap[currency]?.label;
  // 不能含小数的货币，向上取整
  const isIntPrice = ZERO_DECIMAL_CURRENCIES.includes(currency)
  if (isIntPrice) {
    price = Math.ceil(price)
  }
  if (price) {
    price = formatSpePrice(price)
    info = {
      currency,
      currencyLabel,
      price,
      priceTxt: `${currencyLabel} ${price}`
    }
  } else if (pricesSetting[default_country]) {
    let defaultPrice = pricesSetting[default_country] && pricesSetting[default_country][default_currency] ? pricesSetting[default_country][default_currency][valKey] : 0
    // 不能含小数的货币，向上取整
    const isIntPrice = ZERO_DECIMAL_CURRENCIES.includes(default_currency)
    if (isIntPrice) {
      defaultPrice = Math.ceil(defaultPrice)
    } else {
      defaultPrice = defaultPrice.toFixed(2)
    }
    info = {
      currency: default_currency,
      currencyLabel: currencyMap[default_currency]?.label,
      price: defaultPrice,
      priceTxt: defaultPrice
        ? `${currencyMap[default_currency]?.label} ${defaultPrice}`
        : that.$i18n.t('siteBuild.store.noPrice')
    }
  } else {
    info = {
      currency,
      currencyLabel: '$',
      price: 0,
      priceTxt: that.$i18n.t('siteBuild.store.noPrice'),
    }
  }
  return info;
}
// 全建站器统一获取商品信息名称，描述信息方法
const getGoodInfo = (names={}, descs={}, lang='', that=null, langIdData = {}) => {
  const info = {
    name: '',
    desc: '',
  }
  // if (!Object.keys(names).length) {
  //   console.error('请传入名称对象： names')
  //   return info;
  // }
  if (!lang) {
    // eslint-disable-next-line no-console
    console.error('请传入语言环境变量：lang')
    return info;
  }
  if (!that) {
    // eslint-disable-next-line no-console
    console.error('请传入this')
    return info;
  }
  info.name = names.lang_id ? langIdData[names.lang_id] || names.lang_id : names[lang] || names[default_good_lang] || '';
  info.desc =  descs.lang_id ? langIdData[descs.lang_id] || descs.lang_id : descs[lang] || descs[default_good_lang] || '';
  return info;
}

export function assignObject (origin, target, ignoreKeys = []) {
  const object = cloneDeep(origin)
  for (const key in target) {
    if (!ignoreKeys.includes(key)) {
      object[key] = target[key]
    }
  }
  return object
}

const deppMerge = (target, source) => {
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      target[key] =
        target[key] && getType(target).includes('Object')
          ? deppMerge(target[key], source[key])
          : (target[key] = source[key]);
    }
  }
  return target;
}

export function sleep(delay = 0) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      resolve()
      clearTimeout(timer)
    }, delay)
  })
}

const mergeToObject = function(target, source) {
  const obj = cloneDeep(target)
  const origin = cloneDeep(source)
  const merge = (t, s) => {
    for (const key in s) {
      if (getType(s[key]).includes('Object')) {
        merge(t[key], source[key])
      } else {
        obj[key] = source[key]
      }
    }
  }
  merge(obj, origin)
  return obj
}

/*
*   判断是否是语言的两位字符
* */
export function isLangParams (lang){
  return /^[A-Za-z]{2}$/.test(lang)
}

/*
*  取两位字符判断是语言还是页面
* */
export function getLangPageId(lang, pageid, l, p) {
  if (!lang && pageid) {
    if(isLangParams(pageid)){
      l = pageid
      p = '/'
    }else{
      p = pageid
      l = ''
    }

  } else if (lang  && !pageid) {
    if(isLangParams(lang)){
      l = lang
      p = ''
    }else{
      p = lang
      l = ''
    }
  } else {
    l = lang
    p = pageid
  }

  return [l, p]
}
/*
* 通过路由对象获取语言和页面参数
* @{params} params  route.params
*  @{params} isProd  isProd
*  return {sid,lang,pageid}
* */
export function getNeedParamsFormRouteParams(params, isProd) {
  if(isProd === undefined){
    isProd = process.env.IS_BUILD_SITE
  }
  if (!params) {
    // eslint-disable-next-line
    console.warn('params不能为空')
    return params
  }
  const {lang = '', sid, pageid = ''} = params
  let l = ''
  let n = null
  let s = isProd ? (GET_SITE_ID()): sid
  let p = '/'
  const arr = getLangPageId(lang, pageid, l, p)
  const isNative = NativePages.includes(lang) || NativePages.includes(pageid)
  const native = NativePages.find(item => item === lang || item === pageid)
  if (isNative && !isProd) {
    l = NativePages.find(item => item.includes(arr[1])) ? '' : arr[1]
    p = '/'
    n = native
  } else if (!isProd && !sid && !lang && pageid) {
    s = pageid
  } else {
    l = arr[0]
    p = arr[1]
  }
  if (isProd && isNative) {
    n = native
    l = arr[1]
    p = '/'
  }


  return {
    sid: s,
    lang: l || '',
    pageid: p || '/',
    native: n
  }
}


export function objectRemoveKey(target, delKey) {
  const result = {}
  if (typeof target !== 'object') {
    return new Error('Must Be Object')
  }
  for (const key in target) {
    if (key !== delKey) {
      result[key] = target[key]
    }
  }
  return result
}

// 获取浏览器语言，转换成系统支持的语言
export function getSysLanguage() {
  let slang = ''
  const sysLang = window.navigator.language ? window.navigator.language.toLocaleLowerCase() : ''
  const langs = ['en', 'cn', 'ar', 'bg', 'pl', 'de', 'ru', 'fr', 'ko', 'cs', 'ro', 'pt', 'ja', 'th', 'tr', 'es', 'he', 'id', 'it', 'vi', 'tw']
  langs.forEach(item => {
    if (sysLang.includes(item)) {
      slang = item
    }
  })
  return slang
}

export function round(num, decimal) {
  if (isNaN(num)) {
    return 0;
  }
  const p1 = Math.pow(10, decimal + 1);
  const p2 = Math.pow(10, decimal);
  return Math.round(num * p1 / 10) / p2;
}

// 获取当前时间UTC时间
export function getNowUtcStamp() {
  const nowTime = new Date()
  const y = nowTime.getUTCFullYear()
  const m = nowTime.getUTCMonth() + 1
  const d = nowTime.getUTCDate()
  const h = nowTime.getUTCHours()
  const M = nowTime.getUTCMinutes()
  const s = nowTime.getUTCSeconds()
  // const utc = Date.UTC(y, m, d, h, M, s)
  const utc_time = `${y}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d} ${h < 10 ? `0${h}` : h}:${M < 10 ? `0${M}` : M}:${s < 10 ? `0${s}` : s}`
  const stamp = new Date(`${utc_time} GMT+0000`).valueOf() / 1000
  // console.log('now time utc', utc_time);
  return stamp
}

export const rename = (name, removeFlags) => {
  let result = name
  removeFlags.forEach(item => {
    result = result.replaceAll(item, '')
  })
  return result
}

const setFavicon = (icon) => {
  if (!icon) return
  const removeLink = (target) => {
    document.querySelector(target)?.remove()
  }
  const createLink = (rel) => {
    const $link = document.createElement('link')
    $link.rel = rel
    $link.href = icon
    document.head.appendChild($link)
  }
  removeLink('link[rel="shortcut icon"]')
  removeLink('link[rel="apple-touch-icon"]')
  createLink('shortcut icon')
  createLink('alternate icon')
}

const setPageTitle = (seo, langIdData) => {
  if (!seo) return
  document.title = langIdData[seo?.title] || seo?.title
}

export const getImageSize = (url, cb) => {
  const oImg = new Image()
  if (cb) {
    oImg.addEventListener('load', function () {
      const result = {
        width: this.naturalWidth,
        height: this.naturalHeight,
      }
      typeof cb === 'function' && cb(result)
    })
  }
  const rePro = new Promise((resolve) => {
    oImg.addEventListener('load', function () {
      resolve({
        width: this.naturalWidth,
        height: this.naturalHeight,
      });
    });
  });

  oImg.src = url;
  return rePro
}

export const hasOwn = (o, k, type = '', defaultValue = null) => {
  return Object.hasOwn(o || {}, k) ? Object.prototype.toString.call(o[k]).includes(type) ? o[k] : defaultValue : defaultValue
}

export const formatZoneTime = (time, format = 'YYYY-MM-DD HH:mm:ss', isSecond) => {
  const timeStamp = moment.utc(time).local().valueOf()
  return {
    text: !time ? null : moment(timeStamp).format(format),
    value: !time ? null : (isSecond ? timeStamp / 1000 : timeStamp)
  }
}

export const getTextColor = (colorModel, visible) => {
  const isString = typeof colorModel === 'string'
  const color = isString ? colorModel : colorModel.backgroundColor
  const showColor = typeof visible === 'boolean' ? visible : colorModel.backgroundColorShow
  return {
    color: showColor ? color : 'unset'
  }
}

export const navTo = async (context, target) => {
  const toModel = () => {
    const isDialog = target.model.includes('dialog')
    if (isDialog) {
      context.$store.commit('site/SET_DIALOG_VISIBLE', true)
    } else {
      scrollTo(target.model, 'site-body', context.$store.getters.isMobile ? 60 : 90)
    }
  }
  if (target.navType === NavTypeEnum.URL) {
    await context.SitePage.to(target.url)
  } else if (target.navType === NavTypeEnum.PAGE) {
    console.log(target, '12121')
    await context.SitePage.to(target.page)
  } else if (target.navType === NavTypeEnum.MODEL) {
    toModel()
  }
}

export const countdown = (stopTime, context) => {
  let timer
  const runner = () => {
    const now = moment().valueOf()
    const end = formatZoneTime(stopTime * 1000).value
    const leftTime = end - now;
    let d = 0; let h = 0; let m = 0; let s = 0;
    if (leftTime >= 0) {
      d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
      h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
      m = Math.floor(leftTime / 1000 / 60 % 60);
      s = Math.floor(leftTime / 1000 % 60);
    }
    context.countTime = `${d > 0 ? `${d}d:` : ''}${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`
    if (leftTime > 0) timer = setTimeout(runner, 1000)
  }
  runner()
  return timer
}

export const checkUser = (context, fail) => {
  return new Promise((resolve, reject) => {
    if (context.editing) {
     //
    } else if (isNil(context.$store.state.user.siteUserInfo?.id)) {
      context.$store.commit('user/SET_LOGINSHOW', !context.editing)
      context.$store.commit('user/SET_SITE_BIND_USER', true)
      fail && fail()
    } else {
      resolve()
    }
  })
}

export const generateOrderParams = (context, goods) => {
  const return_url = `${window.location.protocol}//${window.location.hostname === 'localhost' ? 'http://localhost:3002/preview/1003574/cn/' : window.location.host}${window.location.pathname}`
  const isSandBox = !isBuildWebSite
  const StoreIpInfo = getLangByip(context)
  const { currency } = StoreIpInfo
  const { project_id, merchant_id } = context.$store.state.user.siteUserInfo
  const ipInfo = getxfip(window.localStorage)
  const priceInfo = getPriceInfo(goods.prices_setting, StoreIpInfo, context)
  const price = +priceInfo.price;
  const device = isMobile() ? 'mobile' : 'desktop'
  const lang = getNeedParamsFormRouteParams(context.$route.params)?.lang || 'en'
  const params = {
    project_id,
    merchant_id,
    currency,
    amount: price,
    from_preview_mode: isSandBox,
    items: [
      {
        item_id: goods.id,
        num: 1,
        amount: price,
        price,
        currency,
        virtual_currency: currency,
        virtual_currency_amount: price,
      },
    ],
    settings: {
      device,
      language: lang,
      show_order: false,
      return_url,
    },
  }
  return context.$api.order.createOrder(params, ipInfo)
}

export const generatePayUrl = (res, callback) => {
  const isSandBox = !isBuildWebSite
  const sandbox = isSandBox ? 1 : 0
  const device = isMobile() ? 'mobile' : 'desktop'
  const { token } = res
  let payUrl = ''
  const env = process.env.RUN_ENV
  const isGoPay = token.slice(0, 2) === 'v2'
  if (isGoPay) {
    payUrl = `${PAY_URL[env]}/new-kop/payelement?access_token=${token}&sandbox=${+sandbox}`
  } else {
    payUrl = `${PAY_URL[env]}/pay/payelement?access_token=${token}&sandbox=${+sandbox}`
  }
  if (device === 'mobile') {
    window.open(payUrl, '_self')
  } else {
    callback(payUrl)
  }
}

export const getLangIdI18n = (names, langIdData) => {
  const langId = names?.lang_id || names?.langId
  if (langId) {
    return langIdData[langId] || names?.lang_id
  } else {
    return 'No Name'
  }
}

export const checkIgnoreMessage = (msg) => {
  return ['数据不存在', 'Data does not exist'].includes(msg)
}

export const eventTracker = (ctx, point_type) => {
  if (!isBuildWebSite) {
    return
  }
  ctx.$api.report.eventTracking({
    project_id: ctx.$store.state.project.info?.project_id,
    store_id: ctx.$store.state.project.info?.id,
    merchant_id: ctx.$store.state.project.info?.merchant_id,
    country: ctx.$store.state.user.ipInfo?.ip_country,
    point_type,
    referer: ctx.$route.query.from,
    device: isMobile() ? 'mobile' : 'pc',
    platform: getDeviceOs()
  })
}

export default {
  // deepClone,
  mergeToObject,
  transMoney,
  rename,
  deppMerge,
  isNumber,
  createId,
  getQueryString,
  getLang,
  getStoreLang,
  loadJsAsync,
  formatDate,
  transformLangToServer,
  getLangByip,
  getxfip,
  getPriceInfo,
  getGoodInfo,
  getLangFromPath,
  getNeedParamsFormRouteParams,
  getSysLanguage,
  getNowUtcStamp,
  setFavicon,
  setPageTitle
}
